<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-chat-dot-round"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>监控点管理</el-breadcrumb-item>
        <el-breadcrumb-item>监控点信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input
            clearable
            size="mini"
            v-model="monitoryPointNameOrMnInput"
            placeholder="监控点名称 / MN号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddMonitoryPoint"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加监控点
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="monitoryPointName" label="监控点名称">
        </el-table-column>
        <el-table-column prop="mn" label="MN号"> </el-table-column>
        <el-table-column prop="monitoryPointType" label="监控点类型">
        </el-table-column>
        <el-table-column prop="longitude" label="经度"> </el-table-column>
        <el-table-column prop="latitude" label="纬度"> </el-table-column>
        <el-table-column prop="companyName" label="所属企业"> </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                @click="handleBinding(scope.$index, scope.row)"
              >
                绑定污染物
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)"
              >
                编辑
              </el-button>
            </div>
            <div>
              <el-button
                size="mini"
                type="text"
                @click="handleRange(scope.$index, scope.row)"
              >
                设置上下限
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleDelete(scope.$index, scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <monitory-point-add
      :dialog-visible="dialogVisibleMonitoryPointAdd"
      @updateVisible="closeMonitoryPointAdd"
    >
    </monitory-point-add>
    <monitory-point-edit
      :dialog-visible="dialogVisibleMonitoryPointEdit"
      :row="editMonitoryPoint"
      @updateVisible="closeMonitoryPointEdit"
    >
    </monitory-point-edit>
    <set-range
      :dialog-visible="dialogVisibleSetRange"
      :row="editMonitoryPoint"
      @updateVisible="closeSetRange"
      ref="range"
    >
    </set-range>
  </div>
</template>

<script>
import MonitoryPointAdd from "@/views/info/point/pointInfo/dialog/MonitoryPointAdd";
import MonitoryPointEdit from "@/views/info/point/pointInfo/dialog/MonitoryPointEdit";
import SetRange from "@/views/info/point/pointInfo/dialog/SetRange";
import router from "@/router";
import { deleteMonitoryPoint, getTable } from "@/api/monitory-point";
import { exportMonitoryPointInfo } from "@/api/export";

export default {
  name: "PointInfo",
  components: { SetRange, MonitoryPointEdit, MonitoryPointAdd },
  data() {
    return {
      monitoryPointNameOrMnInput: "",
      editMonitoryPoint: {},
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisibleMonitoryPointAdd: false,
      dialogVisibleMonitoryPointEdit: false,
      dialogVisibleSetRange: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.monitoryPointNameOrMnInput = "";
    },
    handleSearch() {
      console.log(this.monitoryPointNameOrMnInput);
      this.getList();
    },
    handleExport() {
      exportMonitoryPointInfo();
    },
    handleAddMonitoryPoint() {
      this.dialogVisibleMonitoryPointAdd = true;
    },
    closeMonitoryPointAdd() {
      this.dialogVisibleMonitoryPointAdd = false;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.editMonitoryPoint = JSON.parse(JSON.stringify(row));
      this.dialogVisibleMonitoryPointEdit = true;
    },
    closeMonitoryPointEdit() {
      this.dialogVisibleMonitoryPointEdit = false;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.editMonitoryPoint = row;
      this.$confirm("此操作将永久删除监控点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = { id: row.id };
          deleteMonitoryPoint(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleBinding(index, row) {
      console.log(index, row);
      const monitoryPointId = row.id;
      router.push({ path: "/pointBinding", query: { id: monitoryPointId } });
    },
    handleRange(index, row) {
      console.log(index, row);
      this.editMonitoryPoint = row;
      this.dialogVisibleSetRange = true;
      this.$refs.range.getOptions(row.id);
    },
    closeSetRange() {
      this.dialogVisibleSetRange = false;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      const param = {
        monitoryPointNameOrMn: this.monitoryPointNameOrMnInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
