<template>
  <div>
    <table>
      <tr>
        <td>
          <label>请输入关键字：</label>
          <input style="display: inline" id="tipinput" autocomplete="off" />
        </td>
      </tr>
    </table>
    <div id="GDMap" style="height: 400px"></div>
    <div id="panel"></div>
  </div>
</template>
<script>
import loadMap from "@/utils/loadMap";

export default {
  name: "MapDialog",
  components: {},
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      visible: false,
      mapClick: 0,
      // 地图实例
      GDMap: null,
      // 加载的一些插件
      // 更多参考：https://lbs.amap.com/api/javascript-api/guide/abc/plugins#plugins
      plugins: [
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.OverView",
        "AMap.MouseTool",
        "AMap.PolyEditor",
        "AMap.RectangleEditor",
        "AMap.DistrictLayer",
        "AMap.CustomLayer",
      ],
      // key
      key: "a1610d0afb249c02704d990f97572f83",
      //key: "64c880093eda5bd293e4d5c324e5131b", Autocomplete没有效果
      // 地图版本
      v: "1.4.4",
    };
  },
  computed: {},
  watch: {
    mapClick: {
      handler(val) {
        console.log(val);
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
    this.initMap();
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    // 高德地图加载
    initMap() {
      const _this = this;
      loadMap(this.key, this.plugins, this.v)
        .then((AMap) => {
          this.GDMap = new AMap.Map("GDMap", {
            zoom: 10,
            //center: [119.947, 31.7728]
          });
          this.GDMap.on("complete", () => {
            let auto = new AMap.Autocomplete({
              input: "tipinput",
            });

            let placeSearch = new AMap.PlaceSearch({
              map: this.GDMap,
            }); //构造地点查询类

            AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
            AMap.event.addListener(placeSearch, "markerClick", (e) => {
              console.log(e.data.location.lng, e.data.location.lat); // 经纬度
            });

            function select(e) {
              placeSearch.setCity(e.poi.adcode);
              placeSearch.search(e.poi.name); //关键字查询查询
            }
          });
          // 鼠标点击事件
          this.GDMap.on("click", function (e) {
            const lng = e.lnglat.getLng();
            const lat = e.lnglat.getLat();
            _this.$emit("updateVisible", lng, lat);
          });
        })
        .catch(() => {
          console.log("地图加载失败！");
        });
    },
  },
};
</script>
<style scoped>
#panel {
  position: absolute;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}

.amap-sug-result {
  z-index: 2999 !important;
}
</style>
