<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      width="60%"
      title="添加监控点"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="right"
      >
        <el-form-item
          label-width="100px"
          label="监控点名称"
          prop="monitoryPointName"
        >
          <el-input
            v-model="formData.monitoryPointName"
            placeholder="请输入监控点名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="MN号" prop="mn">
          <el-input
            v-model="formData.mn"
            placeholder="请输入MN号"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="企业名称" prop="companyName">
          <el-select
            v-model="formData.companyName"
            placeholder="请输入企业名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.companyNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="经度" prop="longitude">
          <el-input
            v-model="formData.longitude"
            placeholder="请输入经度"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="纬度" prop="latitude">
          <el-input
            v-model="formData.latitude"
            placeholder="请输入纬度"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="监控点类型"
          prop="monitoryPointType"
        >
          <el-select
            v-model="formData.monitoryPointType"
            placeholder="请输入监控点类型"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.monitoryPointTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="监控点范围"
          prop="monitoryPointRange"
        >
          <el-select
            v-model="formData.monitoryPointRange"
            placeholder="请输入监控点范围"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.monitoryPointRangeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-dialog v-model="dialogVisibleMap" width="50%" append-to-body>
        <map-dialog
          :dialog-visible="dialogVisibleMap"
          @updateVisible="closeMap"
        />
      </el-dialog>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
          <el-button type="success" @click="handleOpenMap()"
            >打开地图</el-button
          >
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCompanyNameOptions } from "@/api/company";
import { getMonitoryPointTypeOptions } from "@/api/monitory-point-type";
import { getCompanyLevelOptions } from "@/api/company-level";
import { addMonitoryPoint } from "@/api/monitory-point";
import MapDialog from "@/views/info/point/pointInfo/dialog/MapDialog";

export default {
  name: "MonitoryPointAdd",
  components: { MapDialog },
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      dialogVisibleMap: false,
      formData: {
        monitoryPointName: "",
        mn: "",
        companyName: "",
        longitude: "",
        latitude: "",
        monitoryPointType: "",
        monitoryPointRange: "",
      },
      options: {
        companyNameOptions: [],
        monitoryPointTypeOptions: [],
        monitoryPointRangeOptions: [],
      },
      rules: {
        monitoryPointName: [
          {
            required: true,
            message: "请输入监控点名称",
            trigger: "blur",
          },
        ],
        mn: [
          {
            required: true,
            message: "请输入MN号",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            required: true,
            message: "请输入经度",
            trigger: "blur",
          },
        ],
        latitude: [
          {
            required: true,
            message: "请输入纬度",
            trigger: "blur",
          },
        ],
        monitoryPointType: [
          {
            required: true,
            message: "请输入监控点类型",
            trigger: "blur",
          },
        ],
        monitoryPointRange: [
          {
            required: true,
            message: "请输入监控点范围",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    // 打开地图
    handleOpenMap() {
      this.dialogVisibleMap = true;
    },
    closeMap(lng, lat) {
      this.dialogVisibleMap = false;
      this.setLongitudeAndLatitude(lng, lat);
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const requestBody = {
          id: undefined,
          monitoryPointName: this.formData.monitoryPointName,
          mn: this.formData.mn,
          companyName: this.formData.companyName,
          longitude: this.formData.longitude,
          latitude: this.formData.latitude,
          monitoryPointType: this.formData.monitoryPointType,
          monitoryPointRange: this.formData.monitoryPointRange,
        };
        addMonitoryPoint(requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
    // 设置经纬度
    setLongitudeAndLatitude(lng, lat) {
      this.formData.longitude = lng;
      this.formData.latitude = lat;
    },
    // 获取企业名称
    // 获取监控点类型
    // 获取企业级别
    getOptions() {
      getCompanyNameOptions().then((result) => {
        this.options.companyNameOptions = result.data;
      });
      getMonitoryPointTypeOptions().then((result) => {
        this.options.monitoryPointTypeOptions = result.data;
      });
      getCompanyLevelOptions().then((result) => {
        this.options.monitoryPointRangeOptions = result.data;
      });
    },
  },
};
</script>
<style></style>
