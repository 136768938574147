<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="设置污染物上下限"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="right"
      >
        <el-form-item label-width="100px" label="污染物" prop="pollutionCode">
          <el-select
            v-model="formData.pollutionCode"
            placeholder="请选择污染物"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
            @change="handleSelectChange"
          >
            <el-option
              v-for="item in options.pollutionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="100px" label="污染物下限" prop="lowerLimit">
          <el-input
            v-model="formData.lowerLimit"
            placeholder="请输入污染物下限"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="污染物上限" prop="upperLimit">
          <el-input
            v-model="formData.upperLimit"
            placeholder="请输入污染物上限"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="100px" label="排除的值" prop="excludeValue">
          <el-input
              v-model="formData.excludeValue"
              placeholder="请输入排除的值，使用英文逗号分割，例如 1,2,3,4,5"
              :maxlength="16"
              clearable
              prefix-icon="el-icon-user"
              :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getBinded, setUpperLower } from "@/api/monitory-point";

export default {
  name: "SetRange",
  components: {},
  props: {
    dialogVisible: Boolean,
    row: Object,
  },
  data() {
    return {
      visible: false,
      formData: {
        pollutionCode: undefined,
        lowerLimit: undefined,
        upperLimit: undefined,
        excludeValue: undefined
      },
      options: {
        pollutionOptions: [],
      },
      monitoryPointId: undefined,
      upperLimit: {},
      lowerLimit: {},
      excludeValue: {},
      rules: {
        lowerLimit: [
          {
            required: true,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        upperLimit: [
          {
            required: true,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleSelectChange(value) {
      this.formData.lowerLimit = this.lowerLimit[value];
      this.formData.upperLimit = this.upperLimit[value];
      this.formData.excludeValue = this.excludeValue[value];
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        // 请求参数
        const params = {
          monitoryPointId: this.monitoryPointId,
          pollutionCode: this.formData.pollutionCode,
          upperLimit: this.formData.upperLimit,
          lowerLimit: this.formData.lowerLimit,
          excludeValue: this.formData.excludeValue,
        };
        // 发送请求
        setUpperLower(params);
        this.close();
      });
    },
    // 获取已绑定的污染物
    getOptions(id) {
      this.monitoryPointId = id;
      const params = { id: id };
      getBinded(params).then((result) => {
        const options = this.$_.map(result.data, "option");
        result.data.forEach((value) => {
          this.upperLimit[value.option.value] = value.upperLimit;
          this.lowerLimit[value.option.value] = value.lowerLimit;
          this.excludeValue[value.option.value] = value.excludeValue;
          this.formData.pollutionCode = value.option.value;
          this.formData.upperLimit = value.upperLimit;
          this.formData.lowerLimit = value.lowerLimit;
          this.formData.excludeValue = value.excludeValue;
        });
        this.options.pollutionOptions = options;
      });
    },
  },
};
</script>
<style></style>
